.header {
  padding: 25px 0;
}

.alert {
  margin: 16px 0;
}

.logo {
  display: inline-block;
  background: white;
  padding: 8px;
  margin: 5px;
  box-shadow: 0 0 5px #ccc;
  border-radius: 30px;
}

.logo img {
  width: 40px;
  height: 40px;
}

.modal-title {
  width: 100%;
}

.service-name {
  margin: 20px 0;
}

.nav-item.active {
  text-decoration: underline;
}

/* .dropdown {
  margin: 25px 0;
} */

#welcome {
  padding: 50px;
  max-width: 500px;
  margin: 10px auto;
}

.badge {
  padding: 10px;
  margin: 10px;
}

.service-name .badge {
  margin: 0;
}

.active-params {
  margin: 20px 0;
}

.active-params .btn {
  margin: 0 10px;
}

.service-search {
  margin: 10px 0;
  min-width: 200px;
  cursor: pointer;
}

td.deploy {
  min-width: 200px;
  cursor: pointer;
}

td.param, th.param, span.times, .nav-item {
  cursor: pointer;
}

/* td.param, th.param {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.param.status {
  max-width: 100px;
}

td.param:hover, th.param:hover {
  text-decoration: underline;
}

.logs {
  background: rgba(0,0,0,0.85);
  color: #ccc;
  padding: 20px;
}

.logs pre {
  color: #ccc;
}

.status-text {
  display: none;
}

/* On screens that are 768px or less, set the background color to olive */
@media screen and (max-width: 768px) {
  table, tbody, tr, th, td {
    border: none !important;
    display: block;
    width: 100% !important;
    max-width: 100%;
    position: relative !important;
  }

  .param.status {
    max-width: 100%;
  }

  .status-text {
    display: inline;
  }

  thead {
    display: none;
  }

  tbody tr {
    border: 1px solid #666;
    border-radius: 10px;
    box-shadow: 0 0 5px #ccc;
    margin: 15px 0;
  }

  tbody td {
    padding: .6rem .8rem;
    min-height: 40px;
    vertical-align: middle;
    text-align: right;
  }

  tbody td:nth-of-type(even) {
    background-color: #ebebeb;
  }

  tbody td:before {
    position: absolute;
    opacity: 0.75;
    top: .8rem;
    left: .8rem;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-label);
    font-size: 1.3rem;
    font-weight: 500;
    text-align: left;
  }
}
